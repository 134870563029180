import React from 'react'

import IntercoOrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import { orange } from 'src/styles/colors'
import { widths } from 'src/styles/breakpoints'

import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import theInterSimplifyData from '../../assets/data/the-inter-simplify.json'

import { Section, Card, Button, LinkButton } from './style'

type theInterSimplifyProps = {
  icon: string;
  title: string;
  description: string;
}

type componentPosp = {
  setIsOpen: Function;
}

const TheInterSimplify = ({ setIsOpen }: componentPosp) => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-5'>
            <h2 className='fs-24 lh-30 fs-lh-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600'>
              <span className='d-block'>O Inter simplifica</span> o Cheque Especial
            </h2>
            {
              width >= widths.md && (
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      element_action: 'click button',
                      element_name: 'Acessar Super App',
                      section_name: 'O Inter simplifica o Cheque Especial',
                    })
                  }}
                >
                  Acessar Super App
                </Button>
              )
            }
          </div>
          <div className='col-12 col-md-7'>
            <div className='row'>
              {
                theInterSimplifyData.map((item: theInterSimplifyProps) => (
                  <div className='col-12 col-md-4 mt-4 mt-md-0' key={item.icon}>
                    <Card>
                      <div>
                        <IntercoOrangeIcon
                          size='MD'
                          color={orange.extra}
                          icon={item.icon}
                          width={37}
                          height={37}
                        />
                        <h3 className='fs-16 lh-20 fs-xl-20 lh-xl-22 fw-600 text-grayscale--400 mt-3'>
                          {item.title}
                        </h3>
                        <p
                          className='fs-14 lh-17 fs-lg-16 lh-lh-20 fs-xl-18 lh-xl-22 text-grayscale--400'
                        >
                          {item.description}
                        </p>
                      </div>
                    </Card>
                  </div>
                ))
              }
              {
                width < widths.md && (
                  <LinkButton
                    href='https://inter-co.onelink.me/Qyu7/89e46kkp'
                    target='_blank'
                    onClick={() =>
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'Acessar Super App',
                        section_name: 'O Inter simplifica o Cheque Especial',
                      })
                    }
                  >
                    Acessar Super App
                  </LinkButton>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TheInterSimplify
