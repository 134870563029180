import React from 'react'
import Img from 'gatsby-image'
import { widths } from 'src/styles/breakpoints'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import PageQuery from '../../pageQuery'

import { Section, Button, LinkButton } from './style'

type componentPosp = {
  setIsOpen: Function;
}

const WhatsIs = ({ setIsOpen }: componentPosp) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const data = PageQuery()
  const width = useWidth(300)

  return (
    <Section className='pb-5 py-md-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row justify-content-md-end'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-3 mb-xl-4'>O que é Cheque Especial?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
              <span className='d-block'>O Cheque Especial é um limite extra disponível para você fazer transações mesmo sem saldo na conta. </span>
              Ele é um tipo de crédito, usado principalmente em casos de emergência e em curto prazo.
            </p>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-xl-5'>
              Caso precise de crédito por mais tempo, confira{' '}
              <a
                className='fw-600'
                target='__blank'
                href='https://inter.co/credito-pessoal-e-credito-imobiliario/'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'outras opções disponíveis',
                    section_name: 'O que é Cheque Especial?',
                    redirect_url: 'https://inter.co/credito-pessoal-e-credito-imobiliario/',
                  })
                }}
              >
                outras opções disponíveis{' '}
              </a>
              no Inter.
            </p>

            {
              width < widths.md && (
                <Img
                  fluid={data.hero.fluid}
                  className='mt-5 mb-3'
                  alt='Tela de celular mostrando a área de cheque especial no Superapp Inter'
                />
              )
            }

            <h2 className='fs-24 lh-30 fs-xl-32 lh-xl-40 text-grayscale--500 fw-600 mb-3 mb-xl-4'>Como funciona o Cheque Especial?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>Precisa de dinheiro para pagar uma conta ou para uma emergência, e não tem o saldo total? Com o Cheque Especial, você realiza seus pagamentos com facilidade e sem atrasos.</p>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>O seu limite pode variar de acordo com a análise de crédito realizada mensalmente. Caso você use o Cheque Especial, são cobrados juros.</p>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-3 mb-xl-4'>Para saber mais, confira o nosso{' '}
              <a
                className='text-orange--extra fw-600'
                target='__blank'
                href='https://marketing.bancointer.com.br/arquivos/pdf/Emprestimos/Regulamento-Cheque-Especial.pdf'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'regulamento',
                    section_name: 'Como funciona o Cheque Especial?',
                    redirect_url: 'https://marketing.bancointer.com.br/arquivos/pdf/Emprestimos/Regulamento-Cheque-Especial.pdf',
                  })
                }}
              >
                regulamento
              </a>.
            </p>

            {
              width >= widths.md ? (
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      element_action: 'click button',
                      element_name: ' Abra sua Conta Digital',
                      section_name: 'Como funciona o Cheque Especial?',
                    })
                  }}
                >
                  Abra sua Conta Digital
                </Button>
               ) : (
                 <LinkButton
                   href='https://inter-co.onelink.me/Qyu7/89e46kkp'
                   target='_blank'
                   onClick={() => sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: ' Abra sua Conta Digital',
                  section_name: 'Como funciona o Cheque Especial?',
                })}
                 >
                   Abra sua Conta Digital
                 </LinkButton>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default WhatsIs
